import { Login, useAccountContext } from 'features/auth';

import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { QuotationList } from 'features/quotation/QuotationList';
import { QuotationDetail } from 'features/quotation/QuotationDetail';
import { DraftQuotataion } from 'features/draft-quotation/DraftQuotation';
import { MedicineList } from 'features/medicine/MedicineList';

export const LOGIN_PATH = '/login';
export const QUOTATIONS_PATH = '/quotations';

export const MEDICINES_PATH = '/medicines';
export const DRAFT_QUOTATIONS_PATH = '/draft-quotation';

const publicRoutes: RouteObject[] = [
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: '*',
    element: <Navigate to={LOGIN_PATH} />,
  },
];

export const userRoutes: RouteObject[] = [
  {
    path: QUOTATIONS_PATH,
    element: <QuotationList/>
  },
  {
    path:`${QUOTATIONS_PATH}/:id`,
    element:<QuotationDetail/>
  },
  {
    path: MEDICINES_PATH,
    element:<MedicineList/>
  },
  {
    path: DRAFT_QUOTATIONS_PATH,
    element:<DraftQuotataion/>
  },
  {
    path: '*',
    element: <Navigate to={DRAFT_QUOTATIONS_PATH} />,
  },
];

export const AppRoutes: React.FC = () => {
  const accountContext = useAccountContext();
  let routes = publicRoutes;
  if(accountContext.account !== undefined){
    routes = userRoutes
  }
  const element = useRoutes(routes);
  return element;
};
