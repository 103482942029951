import { Button, Modal, Alert } from "react-bootstrap";
import {ReactNode} from "react";

interface ConfirmModalProps {
  show: boolean;
  title: string;
  message: ReactNode;
  error?: string | null;
  handleOK: () => void;
  handleCancel: () => void;
}

export const ConfirmModal = ({ show, title, error, message, handleOK, handleCancel }: ConfirmModalProps) => {
  return (
    <Modal show={show}>
      <Modal.Header className="border-bottom-0">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5">
        {error && <Alert variant="danger">{error}</Alert>}
        {message}
      </Modal.Body>
      <Modal.Footer className="border-top-0 d-flex justify-content-between">
        <Button variant="secondary" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button variant="success" onClick={handleOK}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
