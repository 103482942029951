
import { Form } from 'react-bootstrap';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

type FormInputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseControllerProps<TFieldValues>,
  'control'
> &
  Required<Pick<UseControllerProps<TFieldValues>, 'control'>> & {
    label?: string | React.ReactNode;
    type?: string;
    as?: React.ElementType<any>;
    style?:  React.CSSProperties;
    readonly?:boolean;
    placeholder?: string;
  };

export const FormInput = <TFieldValues extends FieldValues = FieldValues>({
  label = undefined,
  type = undefined,
  as = undefined,
  style = undefined,
  readonly=false,
  placeholder=undefined,
  ...props
}: FormInputProps<TFieldValues>) => {

  return (
    <Form.Group className="mb-3">
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        {...props}
        render={({ field, fieldState }) => (
          <>
            <Form.Control type={type} {...field} isInvalid={!!fieldState.error} as={as} style={style} readOnly={readonly} placeholder={placeholder} />
            <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
          </>
        )}
      />
    </Form.Group>
  );
};
