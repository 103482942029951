import { Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 2000,
      }}
    >
      <Spinner animation="border" role="status" variant="light" />
    </div>
  );
};
