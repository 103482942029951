import { Link, useParams } from "react-router-dom";
import {
  useCancelQuotationDetailMutation,
  useQuotationLazyQuery
} from "features/graphql";
import {useEffect, useState} from "react";
import {Button, Col, Container, Row, Table} from "react-bootstrap";
import { parseISODateAndformatDate, sortQuotationDetails } from "../../utils/miscellaneous";
import { Loading } from "components/Loading";
import { ChevronLeft } from "react-bootstrap-icons";
import {QUOTATIONS_PATH} from "route";
import { QuotationDetail as QuotationDetailContent } from '../../features/graphql/apollo';
import {ConfirmModal} from "../../components/ConfirmModal";

type QuotationIdParam = {
  id: string;
};
export const QuotationDetail = () => {
  const param = useParams<QuotationIdParam>();
  const [quotationDetailQuery, { data, loading }] = useQuotationLazyQuery({
    onCompleted({ quotation }) {
      sortQuotationDetails(quotation.details);
    },
  });
  const [cancelQuotationDetail, { loading: medicinesLoding, error }] = useCancelQuotationDetailMutation({
    onCompleted(quotation) {
      const quotationId = param.id;
      if (quotationId !== undefined) {
        quotationDetailQuery({ variables: { quotaitonId: quotationId } });
      }
    },
  });
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState<QuotationDetailContent | null>(null);

  const setSession = () => {
    if(sessionStorage.hasOwnProperty('search-params-temp')) {
      let json = sessionStorage.getItem('search-params-temp');
      if (json) {
        sessionStorage.setItem('search-params', json);
      }
      sessionStorage.removeItem('search-params-temp');
    }
  }
  const handleShow = ({detail}: { detail: QuotationDetailContent }) => {
    setDetail(detail);
    setShow(true);
  }
  const handleClose = () => {
    setDetail(null);
    setShow(false);
  }
  const handleDelete = () => {
    // nullチェックを追加
    if (detail === null) {
      handleClose();
      return;
    }
    // selectedIdを使用して削除リクエストを送る
    cancelQuotationDetail({ variables: {quotationId: detail.quotationId, quotationDetailId: detail.quotationDetailId } });
    // その後モーダルを閉じる
    handleClose();
  }

  useEffect(() => {
    if (param.id) {
      quotationDetailQuery({ variables: { quotaitonId: param.id } });
    }
  }, [param.id, quotationDetailQuery]);

  return (
  <>
    <Container>
      <Row style={{ marginTop: "3rem", marginBottom: "-2rem" }}>
        <Col className="d-flex justify-content-start align-items-start">
          <Link to={QUOTATIONS_PATH} className="text-success text-decoration-none" onClick={() => setSession()}>
            <ChevronLeft className="me-3" />
            依頼履歴一覧に戻る
          </Link>
        </Col>
      </Row>
      {(medicinesLoding || loading) && <Loading />}
    </Container>
    <Container fluid="xl" className="mt-5 px-5 pb-5 sheet" style={{marginBottom: "8rem" }}>
      <Row></Row>
      <Row className="mt-4">
        <Col>
          <div className="fs-3">依頼履歴詳細</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="fs-5 ">
            見積NO. <span className="text-success">{data?.quotation.quotationNo}</span>
          </div>
        </Col>
        <Col></Col>
        <Col className="d-flex justify-content-end">
          <table>
            <tbody>
              <tr>
                <td>依頼日:</td>
                <td>{parseISODateAndformatDate(data?.quotation.requestAt)}</td>
              </tr>
              <tr>
                <td>回答予定日:</td>
                <td>{parseISODateAndformatDate(data?.quotation.answerAt)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <hr />

      <Row className="mt-5">
        <Table className="table-borderless">
          <thead className="text-black-50 border-bottom border-success border-3">
            <tr>
              <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                No
              </th>
              <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                区分
              </th>
              <th style={{ width: "35rem" }}>品名</th>
              <th rowSpan={2}></th>
              <th style={{ width: "15rem" }}>販売元名</th>
              <th style={{ width: "10rem" }}>JANコード</th>
              <th style={{ width: "10rem" }}>YJコード</th>
              <th rowSpan={2}></th>
            </tr>
            <tr>
              <th>規格</th>
              <th>指定卸</th>
              <th></th>
              <th>GS1コード</th>
            </tr>
          </thead>
          {data?.quotation.details?.map((detail, i) => {
            return (
              <tbody key={detail.quotationDetailId} className={detail.quotationDetailStatus === 'キャンセル' ? 'table-secondary' : ''}>
                <tr>
                  <td rowSpan={2} className="align-middle">
                    {i + 1}
                  </td>
                  <td rowSpan={2} className="align-middle">
                    {detail.medicineType}
                  </td>
                  <td>
                    <span className="text-success">{detail.medicineName}</span>
                  </td>
                  <td rowSpan={2} className="align-middle text-nowrap">

                    {detail.quotationDetailStatus=='見積中' && (
                      <Button
                        variant="danger"
                        onClick={() => handleShow({detail: detail})}
                      >
                        キャンセル
                      </Button>
                    )}

                    {detail.quotationDetailStatus=='キャンセル' && (
                      <span className="">キャンセル済</span>
                    )}
                  </td>
                  <td className="border-bottom">{detail.salesManufacturer} </td>
                  <td className="border-bottom">{detail.janCode}</td>
                  <td className="border-bottom">{detail.yjCode}</td>
                </tr>
                <tr>
                  <td>{detail?.capacity}</td>
                  <td>{detail?.wholesaleName}</td>
                  <td></td>
                  <td>{detail.gs1Code}</td>
                </tr>
                <tr　className="border-bottom ">
                  <td colSpan={3}></td>
                  <td colSpan={4} className="text-secondary text-break text-pre-wrap">
                    {detail.note}
                  </td>
                </tr>
              </tbody>
            );
          })}
          <tfoot className="border-top border-success border-3">
            <tr></tr>
          </tfoot>
        </Table>
      </Row>

      <Row className="fs-5">
        <Table className="table-borderless w-auto">
          <tbody>
          <tr>
            <td className="mb-3">注文分類</td>
            <td>:{data?.quotation.orderStatus}</td>
          </tr>
          <tr>
            <td>ご要望など</td>
            <td>:{data?.quotation.note}</td>
          </tr>
          </tbody>
        </Table>
      </Row>

    </Container>

    <ConfirmModal
      show={show}
      title="削除確認"
      message={
        <>
          {detail && detail.medicineName}
          <br/>
          {detail && detail.capacity}
          <br/>
          見積依頼をキャンセルしますか？
          <br/>
          この作業は取り消せません。
        </>
      }
      handleOK={handleDelete}
      handleCancel={handleClose}
    />
</>
  );
};
