import { AccountFragment, useWhoAmILazyQuery } from "features/graphql";
import * as React from "react";

class AccountContextAdapter {
  constructor(
    public account: AccountFragment | undefined = undefined,
    public setAccountVar: (account: AccountFragment | undefined) => void = () => {},
  ) {}

  isLoggedIn() {
    return this.account !== undefined;
  }

  logouted() {
    this.setAccountVar(undefined);
  }

  accountDisplayName() {
    if (this.account?.institution?.institutionName) {
      return `${this.account?.institution?.institutionName} 様`
    }
    return '';
  }
}

const AccountContextRef = React.createRef<AccountContextAdapter>();
const AccountContext = React.createContext<AccountContextAdapter>(new AccountContextAdapter());

export function accountContextRef() {
  return AccountContextRef.current;
}

export function useAccountContext() {
  return React.useContext(AccountContext);
}

interface AccountProviderProps {
  children: React.ReactNode;
}

export const AccountProvider: React.FC<AccountProviderProps> = ({ children }) => {

  const [account, setAccountVar] = React.useState<AccountFragment | undefined>(undefined);
  const accountRef = React.useRef(account);
  const [whoAmIQuery] = useWhoAmILazyQuery({
    onCompleted: (data) => {
      const newAccountInfo = data?.whoAmI ?? undefined;
      if (JSON.stringify(account) !== JSON.stringify(newAccountInfo)) {
        setAccountVar(newAccountInfo);
      }
    },
  });

  const contextValue = React.useMemo<AccountContextAdapter>(
    () => new AccountContextAdapter(account, setAccountVar),
    [account, setAccountVar]
  );

  React.useImperativeHandle(AccountContextRef, () => contextValue);

  React.useEffect(() => {
    accountRef.current = account;
  }, [account]);

  React.useEffect(() => {
    whoAmIQuery();

    const timerId = setInterval(() => {
      if (accountRef.current) {
        whoAmIQuery();
      }
    }, 600000);

    return () => clearInterval(timerId);
  }, [whoAmIQuery]);


  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};
