import { Paging, parseISODateAndformatDate } from "../../utils/miscellaneous";
import { PaginationComponent } from "components/Pagination";
import { useQuotationsLazyQuery, QuotationsResultFragment } from "features/graphql";
import { KeyboardEventHandler, useEffect, useState } from "react";
import {Alert, Form, Col, Container, Row, Table, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { QUOTATIONS_PATH } from "route";
import { Loading } from "components/Loading";
import { graphQlErrorMessage } from "utils/miscellaneous";
import { Search } from "react-bootstrap-icons";

function detailSummary(summary: NonNullable<QuotationsResultFragment["quotations"]>[0]['summary']) {
  if (!summary) {
    return ''
  }

  if (1 === summary.detailCount) {
    return summary.firstDetail.medicineName;
  }
  
  return `${summary.firstDetail.medicineName} 他あり`;
}

/** ラジオボタン設定 */
interface Radio {
  label: string
  value: string
}
export const QuotationList = () => {
  const [paging, setPaging] = useState<Paging>({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("PARTIAL");
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => setSelected(event.target.value);
  const radioButtons: Radio[] = [{ label: "部分一致", value: "PARTIAL" }, { label: "前方一致", value: "PREFIX" }]

  const [qoutationQuery, { data, loading, error }] = useQuotationsLazyQuery();

  useEffect(() => {
    // searchはuseEffectの依存にしない。
    // 入力の度に検索されてしまうから。


    // 👇 前回の検索パラメータがあれば取得 ・・・ ④
    if(sessionStorage.hasOwnProperty('search-params')) {
      let json = sessionStorage.getItem('search-params');
      sessionStorage.removeItem('search-params');
      if (json) {
        let params = JSON.parse(json);
        let usePaging = paging
        usePaging.page = params.page;
        usePaging.pageSize = params.pageSize;
        if (usePaging) {
          setPaging(usePaging)
          setSessionPaging(usePaging.page, usePaging.pageSize)
        }
        if (params.search) {
          setSearch(params.search)
        }
        if (params.selected) {
          setSelected(params.selected)
        }
      }

    }
    qoutationQuery({ variables: { ...paging, filter: search, matchType: selected  } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, qoutationQuery, selected]);

  const onInputChange = (value: string) => {
    setSearch(value);
  };

  const setSessionPaging = (page: Number, pageSize: Number) => {

    let params = {
      page: page,
      pageSize:pageSize,
      search,
      selected
    };

    sessionStorage.setItem('search-params-temp', JSON.stringify(params));

    // @ts-ignore
    setPaging({ page, pageSize});
  };

  const onSeach: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      // 検索の旅に初期化を行う
      setPaging({ page: 0, pageSize: 10 });

      let params = {
        page: paging.page,
        pageSize:paging.pageSize,
        search,
        selected
      };

      sessionStorage.setItem('search-params-temp', JSON.stringify(params));
      qoutationQuery({ variables: { ...paging, filter: search, matchType: selected } });
    }
  };

  return (
    <Container fluid style={{ maxWidth: '1280px', marginBottom: '100px' }} className="mt-5 px-5 pb-4 sheet">
      <Row></Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-between">
          <span className="fs-3">依頼履歴</span>
          <span>
          お問合せは見積番号を記載の上、<span className="text-success">info-gpo@sogo-medical.co.jp</span> までメールにてお問い合わせください。
          </span>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <div className="search">
            <div className="pb-4 d-flex justify-content-end">
              {radioButtons.map((radio, index)  => {
                return (
                  <Form.Check
                    inline
                    key={index}
                    id={`radio-${index}`}
                    className="align-middle pr-1"
                  >
                    <Form.Check.Input
                      type="radio"
                      value={radio.value}
                      checked={radio.value === selected}
                      onChange={changeValue}
                      className="align-middle px-0"
                    />
                    <Form.Check.Label className="align-middle mx-1">{radio.label}</Form.Check.Label>
                  </Form.Check>
                )
              })}
            </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <Search />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="品名 販売元名 JANコード YJコード"
                value={search}
                onKeyPress={(e) => onSeach(e)}
                onChange={(e) => onInputChange(e.target.value)}
              />
            </div>
            <div className="ms-5 form-text">複数のキーワードは空白(スペース)で挟んで並べてください。</div>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>{error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}</Col>
      </Row>
      <Row>
        <Table className="table-borderless">
          <thead className="text-black-50 border-bottom border-success border-3">
            <tr>
              <th rowSpan={2} className="align-middle" style={{ width: "8rem" }}>
                依頼日
              </th>
              <th rowSpan={2} className="align-middle" style={{ width: "8rem" }}>
                希望回答日
              </th>
              <th rowSpan={2} className="align-middle" style={{ width: "8rem" }}>
                状況
              </th>
              <th>見積No.</th>
              <th>主な品名</th>
            </tr>
            <tr>
              <th>ご要望など</th>
            </tr>
          </thead>
          {data?.quotations?.quotations?.map((quotation) => {
            return (
              <tbody key={quotation.quotationId}>
                <tr>
                  <td rowSpan={2} className="align-middle text-nowrap">
                    {parseISODateAndformatDate(quotation.requestAt)}
                  </td>
                  <td rowSpan={2} className="align-middle text-nowrap">
                    {parseISODateAndformatDate(quotation.answerAt)}
                  </td>
                  <td rowSpan={2} className="align-middle text-nowrap">
                    <span className="py-1 px-2"
                    style={{
                      backgroundColor: (quotation.quotationStatus === '完了' ? '#F2F2F2': 'white')
                    }}
                    >
                    {quotation.quotationStatus}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`${QUOTATIONS_PATH}/${quotation.quotationId}`}
                      className="text-success text-decoration-none"
                    >
                      NO. {quotation.quotationNo}
                    </Link>
                  </td>
                  <td>
                    {detailSummary(quotation.summary)}
                  </td>
                  <td rowSpan={2} className="align-middle text-nowrap">
                    {quotation.pdfUrl && (
                      <a
                        target="_blank"
                        href={`${quotation.pdfUrl}`}
                        className="text-success text-decoration-none"
                      >
                        <Button
                          variant="danger"
                        >
                          PDF
                        </Button>
                      </a>
                    )}
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td colSpan={2} className="text-secondary text-truncate text-pre-wrap" style={{ maxWidth: "15rem" }}>
                    {quotation.note}
                  </td>
                </tr>
              </tbody>
            );
          })}
          <tfoot className="border-top border-success border-3">
            <tr></tr>
          </tfoot>
        </Table>
        <PaginationComponent
          total={data?.quotations?.count ?? 0}
          itemsPerPage={paging.pageSize}
          currentPage={paging.page + 1}
          pageSize={paging.pageSize}
          onPageChange={(page) => setSessionPaging(page - 1, paging.pageSize )}
          onPageSize={(pageSize) => setSessionPaging(0, pageSize)}
        />
      </Row>
      {loading && <Loading />}
    </Container>
  );
};
