import { graphqlClient } from "features/graphql";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ApolloProvider } from "@apollo/client";
import { AppRoutes } from "route";
import { AccountProvider } from "features/auth";
import { TopBar } from "features/layout/TopBar";
import { FootBar } from "features/layout/FootBar";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Image from "./logo.png";

const ErrorFallback = () => {
  return (
    <Container fluid className="mt-2 p-0" style={{ width: "600px", background: "white" }}>
      <Row>
        <Col
        style={{ border: "double #008000" }}
        >
          <img src={Image} alt="Logo" className="mt-3" />

          <Alert className="fs-5 my-3" variant="danger">システムに何らかの問題が発生しました。</Alert>
        </Col>
      </Row>
      {/* <Form
        className="d-flex flex-row justify-content-center mt-5 "
        onSubmit={handleSubmit(onSubmit)}
        style={{ border: "double #008000" }}
      >
        <fieldset style={fieldsetStyle}>

          {error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}

          <FormInput name="username" label="ID" control={control} />
          <FormInput name="password" type="password" label="パスワード" control={control} />

          {children}

          <Button type="submit" className="btn w-100 bg-success rounded-pill mb-5">
            ログイン
          </Button>
        </fieldset>
      </Form>
      { loading && <Loading/> } */}
    </Container>
  );
};

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ApolloProvider client={graphqlClient}>
        <AccountProvider>
          <Router>
            <TopBar />
            <AppRoutes />
            <FootBar />
          </Router>
        </AccountProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
