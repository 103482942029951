import { useAccountContext } from "features/auth";
import { useLogoutMutation } from "features/graphql";
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../../logo.svg";
import { BoxArrowRight, FileEarmarkText, Collection } from "react-bootstrap-icons";
import { DRAFT_QUOTATIONS_PATH, QUOTATIONS_PATH } from "route";

export const TopBar = () => {
  const accountContext = useAccountContext();
  const [logoutMutation] = useLogoutMutation();

  const onLogout = () => {
    accountContext.logouted();
    logoutMutation();
  };

  if (!accountContext.isLoggedIn()) {
    return null;
  }

  return (
    <div style={{position: 'sticky', top: 0, zIndex: 100}}>
      <Navbar className="notice">
        <Container fluid="xl">
          <div className="py-0 text-white text-break">{accountContext.account?.notice.notice}</div>
        </Container>
      </Navbar>

      <Navbar className="header">
        <Container fluid="xl">
          <div className="navbar-brand">
            <Link className="" to={DRAFT_QUOTATIONS_PATH}>
              <img src={Image} alt="Logo" style={{ height: 64 }} />
            </Link>

            <Link className="btn ms-5 menu" to={DRAFT_QUOTATIONS_PATH}>
              <FileEarmarkText className="fs-3" />
              <br />
              <span className="">見積依頼</span>
            </Link>
            <Link className="btn ms-3 menu" to={QUOTATIONS_PATH}>
              <Collection className="fs-3" />
              <br />
              <span className="">依頼履歴</span>
            </Link>
          </div>

          <div className="navbar">
            <div className="me-2 text-nowrap ">ようこそ：{accountContext.accountDisplayName()}</div>
            <button className="btn text-success " onClick={() => onLogout()}>
              <BoxArrowRight className="fs-4" />
              <br />
              <small>ログアウト</small>
            </button>
          </div>
        </Container>
      </Navbar>

    </div>
  );
};
