import { ApolloError } from "@apollo/client";
import { format, parse } from "date-fns";
import { DraftQuotationDetail, QuotationDetail} from "features/graphql";

export const REGULAR_ORDER = '定期注文';
export const TEMPORARY_ORDER = '臨時注文';
export const GENERIC_SWITCH_ORDER = 'ジェネリック切替品';

export type Paging = {
  page: number;
  pageSize: number;
};

const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const DATE_FORMAT = 'yyyy/MM/dd';
const JAPANESE_DATE_KANJI_FORMAT = 'yyyy年MM月';
const JAPANESE_DATE_FORMAT = 'yyyy-MM-dd';
// const JAPANESE_DATETIME_FORMAT = `${JAPANESE_DATE_FORMAT} HH:mm`;
const JAPANESE_DATETIME_KANJI_FORMAT = 'MM月dd日';

export function formatDate(date: Date | null | undefined): string {
  if (!date) {
    return '';
  }
  return format(date, DATE_FORMAT);
}

export function formatISODate(date: Date | null | undefined): string {
  if (!date) {
    return '';
  }
  return format(date, ISO_DATE_FORMAT);
}

export function parseISODate(date: string | null | undefined): Date | null {
  if (!date) {
    return null;
  }
  return parse(date, ISO_DATE_FORMAT, new Date());
}

export function parseISODateAndformatDate(date: string | null | undefined): string {
  return formatDate(parseISODate(date));
}

export function formatJapaneseDate(date: Date, kanji: boolean = false): string {
  return format(date, kanji ? JAPANESE_DATE_KANJI_FORMAT : JAPANESE_DATE_FORMAT);
}

export function formatJapaneseDateTime(date: Date | null, kanji: boolean = false): string {
  if(!date){
    return ""
  }
  return format(date, kanji ? JAPANESE_DATETIME_KANJI_FORMAT : JAPANESE_DATE_FORMAT);
}

export const GET_DATA_ERROR = 'データの取得に失敗しました。';
export const PROCESS_ERROR = '処理に失敗しました。';

export function graphQlErrorMessage(error : ApolloError | undefined) {
  if (error) {
    if (error.networkError || 0 < error.clientErrors.length) {
      return 'サーバとの通信に失敗しました。';
    }
    
    if (
      error.graphQLErrors &&
      0 < error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions
    ) {
      const extensions = error.graphQLErrors[0].extensions;

      if (
        extensions['classification'] === 'DataFetchingException' &&
        (extensions['type'] === 'IllegalStateException' ||
          extensions['type'] === 'IllegalArgumentException' ||
          extensions['type'] === 'BadCredentialsException')
      ) {
        return error.message;
      }
    }
    return PROCESS_ERROR;
  }
  return undefined;
}

export function sortQuotationDetails(details: QuotationDetail[] | undefined | null) {
  details?.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))?.map((e, i) => (e.sort = i + 1));
}

export function sortDraftQuotationDetails(details: DraftQuotationDetail[] | undefined | null) {
  details?.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))?.map((e, i) => (e.sort = i + 1));
}
