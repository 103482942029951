import * as z from 'zod';

export const REQUIRED_ERROR = '入力してください。';

// z.stringのパラメータの型であるRawCreateParamsがexportされてないので、以下で型を取得
type RawCreateParams = Parameters<typeof z.string>[0];

export const requiredStringParams: RawCreateParams = {
  invalid_type_error: REQUIRED_ERROR,
  required_error: REQUIRED_ERROR,
};

export function zodRequriredString() {
  return z.string(requiredStringParams).min(1, REQUIRED_ERROR).default('');
}

export function zodRequiredFileList() {
  return z.instanceof(FileList, REQUIRED_ERROR).refine((files) => {
    return files?.length === 1;
  }, REQUIRED_ERROR);
}

export function zodFileLIst(){
  return z.instanceof(FileList)
}
