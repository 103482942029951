import { Button, Modal, Form, Alert } from "react-bootstrap";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormInput } from "components/FormInput";
import { useEffect } from "react";
import { useUpdateDraftQuotationDetailMutation, DraftQuotationDetailFragment } from "features/graphql";
import { graphQlErrorMessage } from "utils/miscellaneous";
import { Loading } from "components/Loading";

interface ModalProps {
  show: boolean;
  title: string;
  targetQuotationDetail: DraftQuotationDetailFragment;
  handleCancel: () => void;
}

const schema = z.object({
  note: z.string().nullable(),
});

type Schema = z.infer<typeof schema>;

export const NoteModal = ({ show, title, targetQuotationDetail, handleCancel }: ModalProps) => {
  const { handleSubmit, reset, control } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      note: targetQuotationDetail.note ?? "",
    },
  });

  const [updateDraftQuotationDetailMutation, { loading, error }] = useUpdateDraftQuotationDetailMutation({
    onCompleted({ updateDraftQuotationDetail }) {
    },
  });

  useEffect(() => {
    if (show) {
      reset({
        note: targetQuotationDetail.note ?? "",
      });
    }
  }, [show, reset]);

  const onSubmit: SubmitHandler<Schema> = (select) => {
    updateDraftQuotationDetailMutation({
      variables: {
        draftQuotationDetailId: targetQuotationDetail?.draftQuotationDetailId,
        wholesaleId: targetQuotationDetail?.wholesaleId,
        note: select.note,
      },
    }).then((res) => {
      if (res.data) {
        handleCancel();
      }
    });
  };

  return (
    <Modal show={show}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}
          <Form.Group className="mb-3">
            <FormInput name="note" control={control} as="textarea" />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="success" type="submit">
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleCancel();
              reset();
            }}
          >
            キャンセル
          </Button>
        </Modal.Footer>
      </Form>
      {loading && <Loading />}
    </Modal>
  );
};
