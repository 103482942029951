import { Container, Navbar } from "react-bootstrap";

export const FootBar = () => {
  return (
    <>
      <Navbar fixed="bottom">
        <Container fluid="xl">
          <div className="w-100 d-flex justify-content-center ">
            <table>
              <tbody>
                <tr>
                  <td>
                    <a href="https://www.sogo-medical.co.jp/others/security.php">
                      情報セキュリティー方針
                    </a>
                  </td>
                  <td>
                    <a href="https://www.sogo-medical.co.jp/others/privacypolicy/">
                      個人情報保護方針
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="copy-right">
                    © SOGO MEDICAL CO., LTD. All Rights Reserved.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
