import {
  useMedicinesLazyQuery,
  MedicineFragment,
  DraftQuotationDetailAddCommand,
  useAddDraftQuotationDetailMutation,
} from "features/graphql";
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from "react";
import { Alert, Container, Table, Row, Col, Button } from "react-bootstrap";
import { PaginationComponent } from "components/Pagination";
import { graphQlErrorMessage, Paging } from "utils/miscellaneous";
import { Loading } from "components/Loading";
import {ChevronLeft, Plus, Search} from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { DRAFT_QUOTATIONS_PATH } from "route";
import {AddMedicine} from "./AddMedicine";

type MedicineType = "外" | "注" | "内" | "歯";
/** ラジオボタン設定 */
interface Radio {
  label: string
  value: string
}
export const MedicineList = () => {
  const [paging, setPaging] = useState<Paging>({ page: 0, pageSize: 10 });
  const [selectedMedicines, setSelectedMedicines] = useState<MedicineFragment[]>([]);
  const [medicineTypes, setMedicineTypes] = useState<MedicineType[]>(["外", "注", "内"]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("PARTIAL");
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => setSelected(event.target.value);
  const radioButtons: Radio[] = [{ label: "部分一致", value: "PARTIAL" }, { label: "前方一致", value: "PREFIX" }]

  const [showModal, setShowModal] = useState<
    "deleteModal" | "addDetailModal" | "deleteDetailModal" | null
  >(null);
  const navigate = useNavigate();

  const [medicinesQuery, { data, loading: medicinesLoding, error }] = useMedicinesLazyQuery();
  const [addDraftQuotationDetailMutation, { loading: addLoading }] = useAddDraftQuotationDetailMutation({
    onCompleted() {
      navigate(DRAFT_QUOTATIONS_PATH);
    },
  });

  useEffect(() => {
    // searchはuseEffectの依存にしない。
    // 入力の度に検索されてしまうから。
    medicinesQuery({ variables: { ...paging, medicineTypes, filter: search, matchType: selected  } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, medicineTypes, medicinesQuery, selected ]);

  const onAdd = (medicine: MedicineFragment, on: boolean) => {
    let newSelectedMedicines: MedicineFragment[];
    if (on) {
      newSelectedMedicines = selectedMedicines.concat(medicine);
    } else {
      newSelectedMedicines = selectedMedicines.filter((m) => m.medicineId !== medicine.medicineId);
    }
    setSelectedMedicines(newSelectedMedicines);
  };

  const onTypeChange = (medicineType: MedicineType, on: boolean) => {
    let newSelectedTypes: MedicineType[];
    if (on) {
      newSelectedTypes = [...medicineTypes, medicineType];
    } else {
      newSelectedTypes = medicineTypes.filter((t) => t !== medicineType);
    }
    setMedicineTypes(newSelectedTypes);
    setPaging({ page: 0, pageSize: paging.pageSize });
    setSelected(selected);
  };

  const handleAddMedicines = () => {
    const commands = selectedMedicines.map((medicine) => {
      const command: DraftQuotationDetailAddCommand = {
        medicineId: medicine.medicineId,
        medicineName: medicine.medicineName,
        janCode: medicine.janCode,
        salesManufacturer: medicine.salesManufacturer ?? "",
        capacity: medicine.capacity,
        yjCode: medicine.yjCode,
        gs1Code: medicine.gs1Code ?? "",
        medicineType: medicine.medicineType,
      };
      return command;
    });
    addDraftQuotationDetailMutation({ variables: { commands } }).then((res) => {});
  };

  const onInputChange = (value: string) => {
    setSearch(value);
  };

  const onSeach: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      // 検索の旅に初期化を行う
      setPaging({ page: 0, pageSize: 10 });
      medicinesQuery({ variables: { ...paging, medicineTypes, filter: search, matchType: selected } });
    }
  };

  return (
    <>
      <Container fluid="xl" className="mt-5 px-5 pb-4 sheet">
        <Row></Row>
        <Row className="mt-4">
          <Col>
            <div className="fs-3">商品を検索</div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <div className="search">
              <div className="pb-4 d-flex justify-content-end">
                {radioButtons.map((radio, index)  => {
                  return (
                    <Form.Check
                      inline
                      key={index}
                      id={`radio-${index}`}
                      className="align-middle pr-1"
                    >
                      <Form.Check.Input
                        type="radio"
                        value={radio.value}
                        checked={radio.value === selected}
                        onChange={changeValue}
                        className="align-middle px-0"
                      />
                      <Form.Check.Label className="align-middle mx-1">{radio.label}</Form.Check.Label>
                    </Form.Check>
                  )
                })}
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <Search />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="品名 販売元名 JANコード YJコード"
                  value={search}
                  onKeyPress={(e) => onSeach(e)}
                  onChange={(e) => onInputChange(e.target.value)}
                />
              </div>
              <div className="ms-5 form-text">複数のキーワードは空白(スペース)で挟んで並べてください。</div>
              <div className="mt-3 d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <Form.Check
                    className="me-5 d-flex align-items-center gap-2 d-flex-center-label"
                    inline
                    label="内"
                    onChange={(e) => {
                      onTypeChange("内", e.target.checked);
                    }}
                    defaultChecked
                  />
                  <Form.Check
                    className="me-5 d-flex align-items-center gap-2 d-flex-center-label"
                    inline
                    label="外"
                    onChange={(e) => {
                      onTypeChange("外", e.target.checked);
                    }}
                    defaultChecked
                  />
                  <Form.Check
                    className="me-5 d-flex align-items-center gap-2 d-flex-center-label"
                    inline
                    label="注"
                    onChange={(e) => {
                      onTypeChange("注", e.target.checked);
                    }}
                    defaultChecked
                  />
                  <Form.Check
                    className="me-5 d-flex align-items-center gap-2 d-flex-center-label"
                    inline
                    label="歯"
                    onChange={(e) => {
                      onTypeChange("歯", e.target.checked);
                    }}
                  />
                </div>

                <div>
                  <Button
                    className="me-5 d-flex align-items-center gap-2"
                    variant="secondary"
                    onClick={() => setShowModal("addDetailModal")}
                  >
                    <Plus className="fs-2 me-5" style={{ height: '1em' }} />
                    <span className="me-5">検索結果にない場合はこちら</span>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>{error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}</Col>
        </Row>

        <Row>
          <Table className="table-borderless">
            <thead className="text-success border-bottom border-success border-3">
              <tr>
                <th rowSpan={3}></th>
                <th rowSpan={3} className="align-middle" style={{ width: "3rem" }}>
                  区分
                </th>
                <th>品名</th>
                <th>先発 / 後発</th>
                {/*<th rowSpan={2} className="align-middle" style={{ width: "15rem" }}>*/}
                {/*  販売元名*/}
                {/*</th>*/}
                <th rowSpan={3} className="align-middle" style={{ width: "10rem" }}>
                  販売開始予定日
                </th>
                <th rowSpan={3} className="align-middle" style={{ width: "10rem" }}>
                  販売終了日
                </th>
                {/*<th rowSpan={2} className="align-middle" style={{ width: "10rem" }}>*/}
                {/*  JANコード*/}
                {/*</th>*/}
                <th style={{ width: "10rem" }}>JANコード</th>
              </tr>
              <tr>
                <th>規格</th>
                <th rowSpan={2} className="align-top" >薬価</th>
                <th>YJコード</th>
              </tr>
              <tr>
                <th>販売元名</th>
                <th>GS1コード</th>
              </tr>
            </thead>

            {data?.medicines?.medicines?.map((medicine) => {
              return (
                <tbody key={medicine.medicineId}>
                  <tr>
                    <td rowSpan={3} className="align-middle">
                      <Form.Check onChange={(e) => onAdd(medicine, e.target.checked)} />
                    </td>
                    <td rowSpan={3} className="align-middle">
                      {medicine.medicineType}
                    </td>
                    <td className="text-success">{medicine.medicineName}</td>
                    <td>{medicine.generic === 1 ? "後" : medicine.generic === 0 ? "先" : ""}</td>
                    {/*<td rowSpan={2} className="align-middle text-break">*/}
                    {/*  {medicine.salesManufacturer}*/}
                    {/*</td>*/}
                    <td rowSpan={3} className="align-middle">
                      {medicine.saleStartExpectedDate}
                    </td>
                    <td rowSpan={3} className="align-middle pre-line" dangerouslySetInnerHTML={{ __html: medicine.stopExpectedDate.replace(/\r\n|\r|\n/g, '<br>') }}>
                      {/* 内容は dangerouslySetInnerHTML を通して挿入されます */}
                    </td>
                    {/*<td rowSpan={2} className="align-middle">*/}
                    {/*  {medicine.janCode}*/}
                    {/*</td>*/}
                    {/*<td>{medicine.yjCode}</td>*/}
                    <td className="align-middle">
                      {medicine.janCode}
                    </td>
                  </tr>
                  <tr>
                    <td>{medicine.capacity}</td>

                    <td rowSpan={2}>{medicine.currentPackagingPrice?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                    <td>{medicine.yjCode}</td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="align-middle text-break">
                      {medicine.salesManufacturer}
                    </td>
                    <td>{medicine.gs1Code}</td>
                  </tr>
                </tbody>
              );
            })}
            <tfoot className="border-top border-success border-3">
              <tr></tr>
            </tfoot>
          </Table>
          <PaginationComponent
            total={data?.medicines?.count ?? 0}
            itemsPerPage={paging.pageSize}
            currentPage={paging.page + 1}
            pageSize={paging.pageSize}
            onPageChange={(page) => {
              setPaging({ page: page - 1, pageSize: paging.pageSize });
              setSelectedMedicines([]);
            }}
            onPageSize={(pageSize) => {
              setPaging({ page: 0, pageSize: +pageSize });
              setSelectedMedicines([]);
            }}
          >
            <Button
              variant="success"
              className="btn-light-gray btn-lg rounded-pill py-3 px-5 me-3"
              disabled={selectedMedicines.length === 0}
              onClick={handleAddMedicines}
            >
              チェックした{selectedMedicines.length} 件を見積に追加
            </Button>
          </PaginationComponent>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginTop: "3rem", marginBottom: "8rem" }}>
          <Col className="d-flex justify-content-center align-items-center">
            <Link to={DRAFT_QUOTATIONS_PATH} className="text-success text-decoration-none">
              <ChevronLeft className="me-3" />
              見積依頼に戻る
            </Link>
          </Col>
        </Row>
      </Container>

      <AddMedicine
        show={showModal === "addDetailModal"}
        handleOk={() => {
          setShowModal(null);
          navigate(DRAFT_QUOTATIONS_PATH);
        }}
        handleCancel={() => setShowModal(null)}
      />

      {(medicinesLoding || addLoading) && <Loading />}
    </>
  );
};
