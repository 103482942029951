import { Button, Modal, Form, Row, Col, Badge, Alert, Container } from "react-bootstrap";
import { z } from "zod";
import { FormInput } from "components/FormInput";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodRequriredString } from "../../utils/zod";
import { DraftQuotationDetailAddCommand, useAddDraftQuotationDetailMutation } from "features/graphql";
import { useEffect } from "react";
import { graphQlErrorMessage } from "utils/miscellaneous";
import { Loading } from "components/Loading";
import { FormSelect } from "components/FormSelect";

interface ModalProps {
  show: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const addMedicineSchema = z.object({
  medicineName: zodRequriredString(),
  janCode: zodRequriredString(),
  salesManufacturer: zodRequriredString(),
  capacity: zodRequriredString(),
  yjCode: z.string(),
  gs1Code: z.string(),
  medicineType: z.string(),
  note: z.string(),
});

type AddMedicineSchema = z.infer<typeof addMedicineSchema>;

export const AddMedicine = ({ show, handleOk, handleCancel }: ModalProps) => {
  const { handleSubmit, control, reset } = useForm<AddMedicineSchema>({
    resolver: zodResolver(addMedicineSchema),
    defaultValues: {
      medicineName: "",
      janCode: "",
      salesManufacturer: "",
      capacity: "",
      yjCode: "",
      gs1Code: "",
      medicineType: "",
      note: "",
    },
  });

  const [addDraftQuotationDetailMutation, { loading, error }] = useAddDraftQuotationDetailMutation({
    onCompleted() {
      handleOk();
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [show]);

  const onSubmit: SubmitHandler<AddMedicineSchema> = (input) => {
    const command: DraftQuotationDetailAddCommand = {
      janCode: input.janCode,
      medicineName: input.medicineName,
      salesManufacturer: input.salesManufacturer,
      yjCode: input.yjCode,
      gs1Code: input.gs1Code,
      medicineType: (input.medicineType.length) ? input.medicineType : null,
      capacity: input.capacity,
      note: input.note,
    };
    addDraftQuotationDetailMutation({ variables: { commands: [command] } });
  };

  return (
    <Modal show={show} size="lg">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Container fluid="xl" className="px-3">
            <Row className="my-3">
              <Col>
                <div className="fs-3">検索結果にないものを追加</div>
              </Col>
            </Row>

            {error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                品名
                <Badge className="ms-1" bg="danger">
                  必須
                </Badge>
              </Form.Label>
              <Col sm={10}>
                <FormInput name="medicineName" control={control} />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={2} className="text-nowrap">
                販売元名
                <Badge className="ms-1" bg="danger">
                  必須
                </Badge>
              </Form.Label>

              <Col sm={4}>
                <FormInput name="salesManufacturer" control={control} />
              </Col>

              <Form.Label column sm={2} className="text-nowrap">
                規格容量
                <Badge className="ms-1" bg="danger">
                  必須
                </Badge>
              </Form.Label>
              <Col sm={4}>
                <FormInput name="capacity" control={control} />
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-nowrap">
                JANコード
                <Badge className="ms-1" bg="danger">
                  必須
                </Badge>
              </Form.Label>

              <Col>
                <FormInput name="janCode" control={control} placeholder="JAN コードがない品名の場合は「0000」をご入力ください" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                使用区分名称
              </Form.Label>

              <Col>
                <FormSelect name="medicineType" control={control}>
                  <option value=""></option>
                  <option value="内">内</option>
                  <option value="外">外</option>
                  <option value="注">注</option>
                  <option value="歯">歯</option>
                </FormSelect>
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={2} className="text-nowrap">
                YJコード
              </Form.Label>
              <Col sm={4}>
                <FormInput name="yjCode" control={control} />
              </Col>
              <Form.Label column sm={2} className="text-nowrap">
                GS1コード
              </Form.Label>
              <Col sm={4}>
                <FormInput name="gs1Code" control={control} />
              </Col>
            </Row>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                備考欄
              </Form.Label>

              <Col>
                <FormInput name="note" control={control} as="textarea" />
              </Col>
            </Form.Group>
          </Container>
        </Modal.Body>

        <Modal.Footer className="border-top-0 d-flex justify-content-center mb-5">
          <Button className="me-3" variant="secondary" onClick={handleCancel}>
            キャンセル
          </Button>
          <Button variant="success" type="submit">
            見積に追加
          </Button>
        </Modal.Footer>
      </Form>
      {loading && <Loading />}
    </Modal>
  );
};
