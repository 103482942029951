import { ApolloClient, InMemoryCache, ApolloLink, ServerParseError, ServerError } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { accountContextRef } from 'features/auth/AccountProvider';

const httpLink = createUploadLink({
  uri: '/api',
});

const errorLink = onError((error: ErrorResponse) => {
  if (error.networkError) {
    switch (error.networkError.name) {
      case 'ServerParseError': {
        const res = error.networkError as ServerParseError;
        error.networkError.message = res.bodyText;
        break;
      }
      case 'ServerError': {
        const res = error.networkError as ServerError;
        if (res.statusCode === 401) {
          accountContextRef()?.logouted();
        }
        break;
      }
      default:
        break;
    }
  } else if (error.graphQLErrors) {
    // アクセス拒否
    const accessDeniedException = error.graphQLErrors.find(
      (graphQLError) => graphQLError.extensions?.type === 'AccessDeniedException'
    );
    if (accessDeniedException) {
      accountContextRef()?.logouted();
    }
  } 
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
});
