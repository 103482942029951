import { Form, FormSelectProps as BsFormSelectProps } from 'react-bootstrap';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

type FormSelectProps<TFieldValues extends FieldValues = FieldValues>
    = BsFormSelectProps 
    & UseControllerProps<TFieldValues>

export const FormSelect = <TFieldValues extends FieldValues = FieldValues>({
  children = null,
  ...props
}: FormSelectProps<TFieldValues>) => {
  return (
    <Form.Group className="mb-3">
      <Controller
        {...props}
        render={({ field, fieldState }) => (
          <>
            <Form.Select {...field} isInvalid={!!fieldState.error}  >
              {children}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
          </>
        )}
      />
    </Form.Group>
  );
};
