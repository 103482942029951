import { useLoginMutation } from "features/graphql";
import { zodRequriredString } from "../../utils/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, Button, Container, Alert } from "react-bootstrap";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInput } from "../../components/FormInput";
import { useAccountContext } from "./AccountProvider";
import Image from "../../logo.png";
import { graphQlErrorMessage } from "utils/miscellaneous";
import { Loading } from "components/Loading";

export interface LoginFromProps {
  fieldsetStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const loginSchema = z.object({
  username: zodRequriredString(),
  password: zodRequriredString(),
});

type LoginSchema = z.infer<typeof loginSchema>;

export const Login = ({ fieldsetStyle = { width: 460 }, children = undefined }: LoginFromProps) => {
  const { handleSubmit, control } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const accountContext = useAccountContext();

  const [loginMutation, { error, loading }] = useLoginMutation({
    onCompleted(data) {
      accountContext.setAccountVar(data.login);
    },
  });

  const onSubmit: SubmitHandler<LoginSchema> = (inputs) => {
    loginMutation({ variables: { username: inputs.username, password: inputs.password } })
  };

  return (
    <Container fluid className="mt-2 p-0" style={{ width: "600px", background: "white" }}>
      <Form
        className="d-flex flex-row justify-content-center mt-5 "
        onSubmit={handleSubmit(onSubmit)}
        style={{ border: "double #008000" }}
      >
        <fieldset style={fieldsetStyle}>
          <img src={Image} alt="Logo" className="mt-3" />

          {error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}
          <p style={{textAlign: "center", fontSize: "24px", fontWeight: "bold"}}>ただいまメンテナンス中です</p>
          <p　style={{textAlign: "center"}}><strong>【メンテナンス日時】</strong></p>
          <p style={{textAlign: "center", color: "red", fontSize: "20px"}}>2024年4月20日（土）18:00～22日（月） 8:00</p>

          <FormInput name="username" label="ID" control={control} />
          <FormInput name="password" type="password" label="パスワード" control={control} />

          {children}

          <Button type="submit" className="btn w-100 bg-success rounded-pill mb-5">
            ログイン
          </Button>
        </fieldset>
      </Form>
      { loading && <Loading/> }
    </Container>
  );
};
