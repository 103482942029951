import {
  DraftQuotationPageQueryResult,
  useDeleteDraftQuotationDetailMutation,
  useDeleteDraftQuotationMutation,
  useUpdateDraftQuotationMutation,
  useDraftQuotationPageLazyQuery,
} from "features/graphql";
import { useEffect, useState } from "react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import { Form, Container, Table, Row, Col, Alert, FormSelect, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConfirmModal } from "components/ConfirmModal";
import { NoteModal } from "./NoteModal";
import {
  formatDate,
  GENERIC_SWITCH_ORDER,
  graphQlErrorMessage,
  parseISODateAndformatDate,
  REGULAR_ORDER,
  TEMPORARY_ORDER,
} from "utils/miscellaneous";
import { Loading } from "components/Loading";
import { SubmitQuotationModal } from "./SubmitQuotationModal";
import { MEDICINES_PATH } from "route";
import { Plus } from "react-bootstrap-icons";
import { FormInput } from "components/FormInput";
import { useUpdateDraftQuotationDetailMutation, DraftQuotationDetailFragment } from "features/graphql";
import { AddMedicine } from "features/medicine/AddMedicine";

const quotationSchema = z.object({
  note: z.string().nullable(),
  orderStatus: z.string().nullable(),
});

type QuotationSchema = z.infer<typeof quotationSchema>;

export const DraftQuotataion = () => {
  const { register, watch, reset, control, getValues } = useForm<QuotationSchema>({
    resolver: zodResolver(quotationSchema),
    defaultValues: {
      note: "",
      orderStatus: null,
    },
  });

  const [pageData, setPageData] = useState<DraftQuotationPageQueryResult["data"] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [pageDataQuery, { loading: pageLoading }] = useDraftQuotationPageLazyQuery({
    onCompleted(data) {
      setPageData(data);
      reset({
        note: data.draftQuotation?.note,
        orderStatus: data.draftQuotation?.orderStatus,
      });
    },
    onError(error) {
      setError(graphQlErrorMessage(error) ?? null);
    },
  });

  const [updateDraftQuotationMutation, { loading: updateLoading }] = useUpdateDraftQuotationMutation({
    onCompleted({ updateDraftQuotation }) {
      if (pageData) {
        setPageData({ ...pageData, draftQuotation: updateDraftQuotation });
      }
    },
    onError(error) {
      setError(graphQlErrorMessage(error) ?? null);
    },
  });

  // 見積詳細更新
  const [updateDraftQuotationDetailMutation, { loading: updateDetailLoading }] = useUpdateDraftQuotationDetailMutation({
    onCompleted({ updateDraftQuotationDetail }) {
      if (pageData) {
        setPageData({ ...pageData, draftQuotation: updateDraftQuotationDetail });
      }
    },
    onError(error) {
      setError(graphQlErrorMessage(error) ?? null);
    },
  });

  // 見積破棄
  const [deleteQuotationMutation, { loading: deleteLoading }] = useDeleteDraftQuotationMutation({
    onCompleted() {
      setPageData(null);
      navigate("/");
    },
    onError(error) {
      setError(graphQlErrorMessage(error) ?? null);
    },
  });

  // 見積詳細削除
  const [deleteDraftQuotationDetailMutation, { loading: deleteDetailLoading }] = useDeleteDraftQuotationDetailMutation({
    onCompleted({ deleteDraftQuotationDetail }) {
      if (pageData) {
        setPageData({ ...pageData, draftQuotation: deleteDraftQuotationDetail });
      }
    },
    onError(error) {
      setError(graphQlErrorMessage(error) ?? null);
    },
  });

  const [showModal, setShowModal] = useState<
    "deleteModal" | "addDetailModal" | "deleteDetailModal" | "noteModal" | "submitModal" | null
  >(null);
  const [targetDraftQuotationDetailId, setTargetDraftQuotationDetailId] = useState<number>();

  useEffect(() => {
    pageDataQuery();

    const subscription = watch((value, { name, type }) => {
      if (name === "orderStatus") {
        updateDraftQuotationMutation({ variables: value });
      }
    });
    return () => subscription.unsubscribe();
  }, [pageDataQuery, watch, updateDraftQuotationMutation]);

  const draftDetails = pageData?.draftQuotation?.details ?? [];

  // const handleUpdate: SubmitHandler<QuotationSchema> = (input) => {
  //   updateDraftQuotationMutation({variables: input})
  // }

  const handleChangeWolesale = (draftQuotationDetail: DraftQuotationDetailFragment, wholesaleId: string) => {
    updateDraftQuotationDetailMutation({
      variables: {
        draftQuotationDetailId: draftQuotationDetail.draftQuotationDetailId,
        wholesaleId: 0 === wholesaleId.length ? null : wholesaleId,
        note: draftQuotationDetail?.note,
      },
    });
  };

  const handleDelete = () => {
    deleteQuotationMutation().then((res) => {
      if (res.data) {
        setShowModal(null);
      }
    });
  };

  const handleDeleteDetail = (id: number) => {
    deleteDraftQuotationDetailMutation({ variables: { draftQuotationDetailId: id } }).then((res) => {
      if (res.data) {
        setTargetDraftQuotationDetailId(undefined);
        setShowModal(null);
      }
    });
  };

  const targetQuotationDetail = draftDetails?.find(
    (detail) => detail.draftQuotationDetailId === targetDraftQuotationDetailId
  );

  return (
    <>
      <Container fluid="xl" className="mt-5 px-5 pb-5 sheet" style={{marginBottom: '10rem'}}>
        <Row></Row>
        <Row className="mt-4">
          <Col>
            <div className="fs-3">見積依頼</div>
          </Col>
          <Col></Col>
          <Col className="d-flex justify-content-end">
            <table>
              <tbody>
                <tr>
                  <td>依頼日:</td>
                  <td>{formatDate(new Date())}</td>
                </tr>
                <tr>
                  <td>回答予定日:</td>
                  <td>{parseISODateAndformatDate(pageData?.selectBusinessCalender.businessDay)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <hr />
        <Row className="mt-2">
          <Col>{error && <Alert variant="danger">{error}</Alert>}</Col>
        </Row>

        <Row className="mt-4">
          <Col className="col-12 d-flex justify-content-center">
            <Link className="btn btn-success me-3" to={MEDICINES_PATH}>
              <Plus className="fs-2 me-5" />
              <span className="me-5">商品を検索</span>
            </Link>
          </Col>
        </Row>

        <Row className="mt-5">
          <Table className="table-borderless">
            <thead className="text-success border-bottom border-success border-3">
              <tr>
                <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                  No
                </th>
                <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                  区分
                </th>
                <th style={{ width: "35rem" }}>品名</th>
                <th style={{ width: "15rem" }}>販売元名</th>
                <th style={{ width: "10rem" }}>JANコード</th>
                <th style={{ width: "10rem" }}>YJコード</th>
                <th rowSpan={2}></th>
              </tr>
              <tr>
                <th>規格</th>
                <th>指定卸</th>
                <th></th>
                <th>GS1コード</th>
              </tr>
            </thead>
            {draftDetails.map((detail, i) => {
              return (
                <tbody key={detail.draftQuotationDetailId}>
                  <tr>
                    <td colSpan={6}></td>
                    <td rowSpan={2} className="p-0">
                      <Button
                        type="button"
                        className="btn-close p-0"
                        size="sm"
                        onClick={() => {
                          setError(null);
                          setTargetDraftQuotationDetailId(detail.draftQuotationDetailId);
                          setShowModal("deleteDetailModal");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2} className="align-middle text-center">
                      {i + 1}
                    </td>
                    <td rowSpan={2} className="align-middle">
                      {detail.medicineType}
                    </td>
                    <td className="text-success">
                      <span>{detail.medicineName}</span>
                    </td>
                    <td className="border-bottom">{detail.salesManufacturer} </td>
                    <td className="border-bottom">{detail.janCode}</td>
                    <td className="border-bottom">{detail.yjCode}</td>
                  </tr>
                  <tr>
                    <td>{detail?.capacity}</td>
                    <td>
                      {(pageData?.wholesales?.length ?? 0) !== 0 && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              指定卸、注文済卸が
                              <br />
                              あれば選択してください
                            </Tooltip>
                          }
                        >
                          <FormSelect
                            value={detail.wholesaleId ?? ""}
                            onChange={(e) => handleChangeWolesale(detail, e.target.value)}
                          >
                            <option value="">注文済み、指定卸なし</option>
                            {pageData?.wholesales?.map((wholesale) => {
                              return (
                                <option key={wholesale.wholesaleId} value={wholesale.wholesaleId}>
                                  {wholesale.wholesaleName}
                                </option>
                              );
                            })}
                          </FormSelect>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td></td>
                    <td>{detail.gs1Code}</td>
                  </tr>
                  <tr className="border-bottom">
                    <td colSpan={3}></td>
                    <td colSpan={3} className="text-secondary text-break text-pre-wrap p-1">
                      {detail.note}
                      <Button
                        variant="outline-secondary"
                        className="ms-2"
                        size="sm"
                        onClick={() => {
                          setError(null);
                          setTargetDraftQuotationDetailId(detail.draftQuotationDetailId);
                          setShowModal("noteModal");
                        }}
                      >
                        {detail.note?.length ?? 0 ? "編集" : "備考を追加"}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
            <tfoot className="border-top border-success border-3">
              <tr></tr>
            </tfoot>
          </Table>
        </Row>

        {0 < draftDetails.length && (
          <>
            <Form>
              <Row className="fs-5 mt-3">
                <Form.Label className="mb-3">注文分類</Form.Label>
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    value={REGULAR_ORDER}
                    label={REGULAR_ORDER}
                    type="radio"
                    {...register("orderStatus")}
                  />

                  <Form.Check
                    inline
                    value={TEMPORARY_ORDER}
                    label={TEMPORARY_ORDER}
                    type="radio"
                    {...register("orderStatus")}
                  />

                  <Form.Check
                    inline
                    value={GENERIC_SWITCH_ORDER}
                    label={GENERIC_SWITCH_ORDER}
                    type="radio"
                    {...register("orderStatus")}
                  />
                </Form.Group>
              </Row>

              <Row className="fs-5 mt-3">
                <FormInput
                  label="ご要望など"
                  name="note"
                  control={control}
                  as="textarea"
                  placeholder="回答を急ぎたいものなど見積依頼時の連絡としてもお使いください。"
                  style={{
                    height: "calc(1.5rem * 3)",
                    lineHeight: "1.5",
                  }}
                />
              </Row>
            </Form>

            <Row className="mt-3 mb-5">
              <Col className="col-12 d-flex justify-content-center">
                <Button
                  variant="secondary"
                  className="btn-lg me-3"
                  onClick={() => {
                    setError(null);
                    setShowModal("deleteModal");
                  }}
                >
                  見積破棄
                </Button>

                <Button
                  variant="success"
                  className="btn-lg"
                  onClick={() => {
                    setError(null);
                    setShowModal("submitModal");
                  }}
                >
                  内容確認
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <AddMedicine
        show={showModal === "addDetailModal"}
        handleOk={() => {
          pageDataQuery();
          setShowModal(null);
        }}
        handleCancel={() => setShowModal(null)}
      />

      <ConfirmModal
        show={showModal === "deleteModal"}
        title="見積破棄"
        message={"現在選択中の商品をすべて削除しますか？"}
        error={error}
        handleOK={handleDelete}
        handleCancel={() => setShowModal(null)}
      />

      {targetQuotationDetail && (
        <ConfirmModal
          show={showModal === "deleteDetailModal"}
          title="品名削除"
          message={`${targetQuotationDetail.medicineName!!}を削除しますか？`}
          error={error}
          handleOK={() => handleDeleteDetail(targetQuotationDetail.draftQuotationDetailId)}
          handleCancel={() => setShowModal(null)}
        />
      )}

      {targetQuotationDetail && (
        <NoteModal
          show={showModal === "noteModal"}
          title="備考"
          targetQuotationDetail={targetQuotationDetail}
          handleCancel={() => {
            setShowModal(null);
            setTargetDraftQuotationDetailId(undefined);
            pageDataQuery();
          }}
        />
      )}

      <SubmitQuotationModal
        show={showModal === "submitModal"}
        note={getValues("note") ?? undefined}
        handleOk={() => {
          pageDataQuery();
          setShowModal(null);
          reset();
        }}
        handleCancel={() => {
          setShowModal(null);
        }}
      />

      {(pageLoading || updateLoading || deleteLoading || updateDetailLoading || deleteDetailLoading) && <Loading />}
    </>
  );
};
