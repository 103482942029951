import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDate: any;
  LocalDateTime: any;
  Long: any;
  Upload: File;
  YearMonth: any;
  MatchType: String;
};

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['String'];
  contractor: Contractor;
  contractorId: Scalars['String'];
  institution: Institution;
  institutionId: Scalars['String'];
  notice: Notice;
};

export type BusinessCalender = {
  __typename?: 'BusinessCalender';
  businessDay?: Maybe<Scalars['LocalDate']>;
};

export type Contractor = {
  __typename?: 'Contractor';
  contractorId: Scalars['String'];
  contractorName: Scalars['String'];
};

export type DraftQuotation = {
  __typename?: 'DraftQuotation';
  accountId: Scalars['String'];
  answerAt?: Maybe<Scalars['String']>;
  createAt?: Maybe<Scalars['String']>;
  details?: Maybe<Array<DraftQuotationDetail>>;
  note?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
};

export type DraftQuotationDetail = {
  __typename?: 'DraftQuotationDetail';
  accountId: Scalars['String'];
  capacity?: Maybe<Scalars['String']>;
  draftQuotationDetailId: Scalars['Long'];
  gs1Code?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  medicineId?: Maybe<Scalars['String']>;
  medicineName?: Maybe<Scalars['String']>;
  medicineType?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  salesManufacturer?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  wholesaleId?: Maybe<Scalars['String']>;
  wholesaleName?: Maybe<Scalars['String']>;
  yjCode?: Maybe<Scalars['String']>;
};

export type DraftQuotationDetailAddCommand = {
  capacity: Scalars['String'];
  gs1Code: Scalars['String'];
  janCode: Scalars['String'];
  medicineId?: InputMaybe<Scalars['String']>;
  medicineName: Scalars['String'];
  medicineType?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  salesManufacturer: Scalars['String'];
  yjCode?: InputMaybe<Scalars['String']>;
};

export type Institution = {
  __typename?: 'Institution';
  contractorId: Scalars['String'];
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
};

export type Medicine = {
  __typename?: 'Medicine';
  capacity: Scalars['String'];
  currentPackagingPrice?: Maybe<Scalars['Float']>;
  generic?: Maybe<Scalars['Int']>;
  gs1Code?: Maybe<Scalars['String']>;
  janCode: Scalars['String'];
  medicineId: Scalars['String'];
  medicineName: Scalars['String'];
  medicineType?: Maybe<Scalars['String']>;
  salesManufacturer: Scalars['String'];
  yjCode?: Maybe<Scalars['String']>;
  saleStartExpectedDate?: Maybe<Scalars['String']>;
  stopExpectedDate?: Maybe<Scalars['String']>;
};

export type MedicinesResult = {
  __typename?: 'MedicinesResult';
  count: Scalars['Int'];
  medicines?: Maybe<Array<Medicine>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDraftQuotationDetail?: Maybe<DraftQuotation>;
  deleteDraftQuotation?: Maybe<Scalars['Boolean']>;
  deleteDraftQuotationDetail?: Maybe<DraftQuotation>;
  login: Account;
  logout?: Maybe<Scalars['Boolean']>;
  submitQuotation?: Maybe<Scalars['Boolean']>;
  updateDraftQuotation?: Maybe<DraftQuotation>;
  updateDraftQuotationDetail?: Maybe<DraftQuotation>;
};


export type MutationAddDraftQuotationDetailArgs = {
  commands: Array<DraftQuotationDetailAddCommand>;
};


export type MutationDeleteDraftQuotationDetailArgs = {
  draftQuotationDetailId: Scalars['Long'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationSubmitQuotationArgs = {
  note?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDraftQuotationArgs = {
  note?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDraftQuotationDetailArgs = {
  draftQuotationDetailId: Scalars['Long'];
  note?: InputMaybe<Scalars['String']>;
  wholesaleId?: InputMaybe<Scalars['String']>;
};

export type Notice = {
  __typename?: 'Notice';
  notice: Scalars['String'];
  noticeAt?: Maybe<Scalars['LocalDate']>;
};

export type Query = {
  __typename?: 'Query';
  draftQuotation?: Maybe<DraftQuotation>;
  medicines?: Maybe<MedicinesResult>;
  quotation: Quotation;
  quotations?: Maybe<QuotationsResult>;
  selectBusinessCalender: BusinessCalender;
  whoAmI?: Maybe<Account>;
  wholesales?: Maybe<Array<Wholesale>>;
};


export type QueryMedicinesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  medicineTypes?: InputMaybe<Array<Scalars['String']>>;
  matchType?: InputMaybe<Scalars['MatchType']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryQuotationArgs = {
  quotationId: Scalars['String'];
};


export type QueryQuotationsArgs = {
  matchType?: InputMaybe<Scalars['MatchType']>;
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Quotation = {
  __typename?: 'Quotation';
  answerAt: Scalars['LocalDate'];
  contractorId: Scalars['String'];
  createAt: Scalars['LocalDateTime'];
  details?: Maybe<Array<QuotationDetail>>;
  institutionId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  quotationId: Scalars['String'];
  quotationNo: Scalars['String'];
  quotationStatus: Scalars['String'];
  requestAt: Scalars['LocalDate'];
  summary?: Maybe<QuotationDetailSummary>;
};

export type QuotationDetail = {
  __typename?: 'QuotationDetail';
  capacity: Scalars['String'];
  gs1Code?: Maybe<Scalars['String']>;
  janCode: Scalars['String'];
  medicineId?: Maybe<Scalars['String']>;
  medicineName: Scalars['String'];
  medicineType?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  quotationDetailId: Scalars['String'];
  quotationId: Scalars['String'];
  salesManufacturer: Scalars['String'];
  sort: Scalars['Int'];
  wholesaleId?: Maybe<Scalars['String']>;
  wholesaleName?: Maybe<Scalars['String']>;
  yjCode?: Maybe<Scalars['String']>;
  quotationDetailStatus?: Maybe<Scalars['String']>;
};

export type QuotationDetailSummary = {
  __typename?: 'QuotationDetailSummary';
  detailCount: Scalars['Int'];
  firstDetail: QuotationDetail;
};

export type QuotationsResult = {
  __typename?: 'QuotationsResult';
  count: Scalars['Int'];
  quotations?: Maybe<Array<Quotation>>;
};

export type Wholesale = {
  __typename?: 'Wholesale';
  wholesaleId: Scalars['String'];
  wholesaleName: Scalars['String'];
};

export type AccountFragment = { __typename?: 'Account', accountId: string, contractor: { __typename?: 'Contractor', contractorName: string }, institution: { __typename?: 'Institution', institutionName: string }, notice: { __typename?: 'Notice', notice: string, noticeAt?: any | null } };

export type MedicineFragment = { __typename?: 'Medicine', medicineId: string, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, generic?: number | null, currentPackagingPrice?: number | null };

export type WholesaleFragment = { __typename?: 'Wholesale', wholesaleId: string, wholesaleName: string };

export type QuotationFragment = { __typename?: 'Quotation', quotationId: string, quotationNo: string, quotationStatus: string, orderStatus?: string | null, requestAt: any, createAt: any, answerAt: any, note?: string | null, pdfUrl?: string | null };

export type QuotationDetailFragment = { __typename?: 'QuotationDetail', quotationDetailId: string, quotationId: string, medicineId?: string | null, wholesaleId?: string | null, sort: number, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, note?: string | null, wholesaleName?: string | null };

export type DraftQuotationWithDetailsFragment = { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null };

export type DraftQuotationDetailFragment = { __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null };

export type BusinessCalenderFragment = { __typename?: 'BusinessCalender', businessDay?: any | null };

export type MedicinesResultFragment = { __typename?: 'MedicinesResult', count: number, medicines?: Array<{ __typename?: 'Medicine', medicineId: string, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, generic?: number | null, currentPackagingPrice?: number | null }> | null };

export type QuotationsResultFragment = { __typename?: 'QuotationsResult', count: number, quotations?: Array<{ __typename?: 'Quotation', quotationId: string, quotationNo: string, quotationStatus: string, orderStatus?: string | null, requestAt: any, createAt: any, answerAt: any, note?: string | null, pdfUrl?: string | null, summary?: { __typename?: 'QuotationDetailSummary', detailCount: number, firstDetail: { __typename?: 'QuotationDetail', quotationDetailId: string, quotationId: string, medicineId?: string | null, wholesaleId?: string | null, sort: number, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, note?: string | null, wholesaleName?: string | null } } | null }> | null };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI?: { __typename?: 'Account', accountId: string, contractor: { __typename?: 'Contractor', contractorName: string }, institution: { __typename?: 'Institution', institutionName: string }, notice: { __typename?: 'Notice', notice: string, noticeAt?: any | null } } | null };

export type MedicinesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
  medicineTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  matchType?: InputMaybe<Scalars['MatchType']>;
}>;


export type MedicinesQuery = { __typename?: 'Query', medicines?: { __typename?: 'MedicinesResult', count: number, medicines?: Array<{ __typename?: 'Medicine', medicineId: string, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, generic?: number | null, currentPackagingPrice?: number | null, saleStartExpectedDate: string, stopExpectedDate: string }> | null } | null };

export type QuotationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
  matchType?: InputMaybe<Scalars['MatchType']>;
}>;


export type QuotationsQuery = { __typename?: 'Query', quotations?: { __typename?: 'QuotationsResult', count: number, quotations?: Array<{ __typename?: 'Quotation', quotationId: string, quotationNo: string, quotationStatus: string, orderStatus?: string | null, requestAt: any, createAt: any, answerAt: any, note?: string | null, pdfUrl?: string | null, summary?: { __typename?: 'QuotationDetailSummary', detailCount: number, firstDetail: { __typename?: 'QuotationDetail', quotationDetailId: string, quotationId: string, medicineId?: string | null, wholesaleId?: string | null, sort: number, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, note?: string | null, wholesaleName?: string | null } } | null }> | null } | null };

export type QuotationQueryVariables = Exact<{
  quotaitonId: Scalars['String'];
}>;


export type QuotationQuery = { __typename?: 'Query', quotation: { __typename?: 'Quotation', quotationId: string, quotationNo: string, quotationStatus: string, orderStatus?: string | null, requestAt: any, createAt: any, answerAt: any, note?: string | null, pdfUrl?: string | null, details?: Array<{ __typename?: 'QuotationDetail', quotationDetailId: string, quotationId: string, medicineId?: string | null, wholesaleId?: string | null, sort: number, janCode: string, medicineName: string, salesManufacturer: string, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity: string, note?: string, quotationDetailStatus?: string | null, wholesaleName?: string | null }> | null } };

export type DraftQuotationPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DraftQuotationPageQuery = { __typename?: 'Query', draftQuotation?: { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null } | null, wholesales?: Array<{ __typename?: 'Wholesale', wholesaleId: string, wholesaleName: string }> | null, selectBusinessCalender: { __typename?: 'BusinessCalender', businessDay?: any | null } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Account', accountId: string, contractor: { __typename?: 'Contractor', contractorName: string }, institution: { __typename?: 'Institution', institutionName: string }, notice: { __typename?: 'Notice', notice: string, noticeAt?: any | null } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: boolean | null };

export type AddDraftQuotationDetailMutationVariables = Exact<{
  commands: Array<DraftQuotationDetailAddCommand> | DraftQuotationDetailAddCommand;
}>;


export type AddDraftQuotationDetailMutation = { __typename?: 'Mutation', addDraftQuotationDetail?: { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null } | null };

export type UpdateDraftQuotationMutationVariables = Exact<{
  note?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDraftQuotationMutation = { __typename?: 'Mutation', updateDraftQuotation?: { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null } | null };

export type DeleteDraftQuotationMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteDraftQuotationMutation = { __typename?: 'Mutation', deleteDraftQuotation?: boolean | null };

export type UpdateDraftQuotationDetailMutationVariables = Exact<{
  draftQuotationDetailId: Scalars['Long'];
  wholesaleId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDraftQuotationDetailMutation = { __typename?: 'Mutation', updateDraftQuotationDetail?: { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null } | null };

export type DeleteDraftQuotationDetailMutationVariables = Exact<{
  draftQuotationDetailId: Scalars['Long'];
}>;


export type DeleteDraftQuotationDetailMutation = { __typename?: 'Mutation', deleteDraftQuotationDetail?: { __typename?: 'DraftQuotation', answerAt?: string | null, orderStatus?: string | null, note?: string | null, details?: Array<{ __typename?: 'DraftQuotationDetail', draftQuotationDetailId: any, accountId: string, medicineId?: string | null, wholesaleId?: string | null, sort?: number | null, janCode?: string | null, medicineName?: string | null, salesManufacturer?: string | null, yjCode?: string | null, gs1Code?: string | null, medicineType?: string | null, capacity?: string | null, note?: string | null, wholesaleName?: string | null }> | null } | null };

export type SubmitQuotationMutationVariables = Exact<{
  note?: InputMaybe<Scalars['String']>;
}>;


export type SubmitQuotationMutation = { __typename?: 'Mutation', submitQuotation?: boolean | null };

export type CancelQuotationDetailMutation = { __typename?: 'Mutation', quotationId: string, quotationDetailId: string};

export type CancelQuotationDetailMutationVariables = Exact<{
  quotationId: Scalars['String'];
  quotationDetailId: Scalars['String'];
}>;

export const AccountFragmentDoc = gql`
    fragment Account on Account {
  accountId
  contractor {
    contractorName
  }
  institution {
    institutionName
  }
  notice {
    notice
    noticeAt
  }
}
    `;
export const WholesaleFragmentDoc = gql`
    fragment Wholesale on Wholesale {
  wholesaleId
  wholesaleName
}
    `;
export const DraftQuotationDetailFragmentDoc = gql`
    fragment DraftQuotationDetail on DraftQuotationDetail {
  draftQuotationDetailId
  accountId
  medicineId
  wholesaleId
  sort
  janCode
  medicineName
  salesManufacturer
  yjCode
  gs1Code
  medicineType
  capacity
  note
  wholesaleName
}
    `;
export const DraftQuotationWithDetailsFragmentDoc = gql`
    fragment DraftQuotationWithDetails on DraftQuotation {
  answerAt
  orderStatus
  note
  details {
    ...DraftQuotationDetail
  }
}
    ${DraftQuotationDetailFragmentDoc}`;
export const BusinessCalenderFragmentDoc = gql`
    fragment BusinessCalender on BusinessCalender {
  businessDay
}
    `;
export const MedicineFragmentDoc = gql`
    fragment Medicine on Medicine {
  medicineId
  janCode
  medicineName
  salesManufacturer
  yjCode
  gs1Code
  medicineType
  capacity
  generic
  currentPackagingPrice
  saleStartExpectedDate
  stopExpectedDate
}
    `;
export const MedicinesResultFragmentDoc = gql`
    fragment MedicinesResult on MedicinesResult {
  count
  medicines {
    ...Medicine
  }
}
    ${MedicineFragmentDoc}`;
export const QuotationFragmentDoc = gql`
    fragment Quotation on Quotation {
  quotationId
  quotationNo
  quotationStatus
  orderStatus
  requestAt
  createAt
  answerAt
  note
  pdfUrl
}
    `;
export const QuotationDetailFragmentDoc = gql`
    fragment QuotationDetail on QuotationDetail {
  quotationDetailId
  quotationId
  medicineId
  wholesaleId
  sort
  janCode
  medicineName
  salesManufacturer
  yjCode
  gs1Code
  medicineType
  capacity
  note
  wholesaleName
  quotationDetailStatus
}
    `;
export const QuotationsResultFragmentDoc = gql`
    fragment QuotationsResult on QuotationsResult {
  count
  quotations {
    ...Quotation
    summary {
      detailCount
      firstDetail {
        ...QuotationDetail
      }
    }
  }
}
    ${QuotationFragmentDoc}
${QuotationDetailFragmentDoc}`;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const MedicinesDocument = gql`
    query Medicines($page: Int, $pageSize: Int, $filter: String, $medicineTypes: [String!], $matchType: MatchType) {
  medicines(
    page: $page
    pageSize: $pageSize
    filter: $filter
    medicineTypes: $medicineTypes
    matchType: $matchType
  ) {
    ...MedicinesResult
  }
}
    ${MedicinesResultFragmentDoc}`;

/**
 * __useMedicinesQuery__
 *
 * To run a query within a React component, call `useMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicinesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      medicineTypes: // value for 'medicineTypes'
 *   },
 * });
 */
export function useMedicinesQuery(baseOptions?: Apollo.QueryHookOptions<MedicinesQuery, MedicinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicinesQuery, MedicinesQueryVariables>(MedicinesDocument, options);
      }
export function useMedicinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicinesQuery, MedicinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicinesQuery, MedicinesQueryVariables>(MedicinesDocument, options);
        }
export type MedicinesQueryHookResult = ReturnType<typeof useMedicinesQuery>;
export type MedicinesLazyQueryHookResult = ReturnType<typeof useMedicinesLazyQuery>;
export type MedicinesQueryResult = Apollo.QueryResult<MedicinesQuery, MedicinesQueryVariables>;
export const QuotationsDocument = gql`
    query Quotations($page: Int, $pageSize: Int, $filter: String, $matchType: MatchType) {
  quotations(page: $page, pageSize: $pageSize, filter: $filter, matchType: $matchType) {
    ...QuotationsResult
  }
}
    ${QuotationsResultFragmentDoc}`;

/**
 * __useQuotationsQuery__
 *
 * To run a query within a React component, call `useQuotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      matchType: // value for 'matchType'
 *   },
 * });
 */
export function useQuotationsQuery(baseOptions?: Apollo.QueryHookOptions<QuotationsQuery, QuotationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotationsQuery, QuotationsQueryVariables>(QuotationsDocument, options);
      }
export function useQuotationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotationsQuery, QuotationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotationsQuery, QuotationsQueryVariables>(QuotationsDocument, options);
        }
export type QuotationsQueryHookResult = ReturnType<typeof useQuotationsQuery>;
export type QuotationsLazyQueryHookResult = ReturnType<typeof useQuotationsLazyQuery>;
export type QuotationsQueryResult = Apollo.QueryResult<QuotationsQuery, QuotationsQueryVariables>;
export const QuotationDocument = gql`
    query Quotation($quotaitonId: String!) {
  quotation(quotationId: $quotaitonId) {
    ...Quotation
    details {
      ...QuotationDetail
    }
  }
}
    ${QuotationFragmentDoc}
${QuotationDetailFragmentDoc}`;

/**
 * __useQuotationQuery__
 *
 * To run a query within a React component, call `useQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationQuery({
 *   variables: {
 *      quotaitonId: // value for 'quotaitonId'
 *   },
 * });
 */
export function useQuotationQuery(baseOptions: Apollo.QueryHookOptions<QuotationQuery, QuotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotationQuery, QuotationQueryVariables>(QuotationDocument, options);
      }
export function useQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotationQuery, QuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotationQuery, QuotationQueryVariables>(QuotationDocument, options);
        }
export type QuotationQueryHookResult = ReturnType<typeof useQuotationQuery>;
export type QuotationLazyQueryHookResult = ReturnType<typeof useQuotationLazyQuery>;
export type QuotationQueryResult = Apollo.QueryResult<QuotationQuery, QuotationQueryVariables>;
export const DraftQuotationPageDocument = gql`
    query DraftQuotationPage {
  draftQuotation {
    ...DraftQuotationWithDetails
  }
  wholesales {
    ...Wholesale
  }
  selectBusinessCalender {
    ...BusinessCalender
  }
}
    ${DraftQuotationWithDetailsFragmentDoc}
${WholesaleFragmentDoc}
${BusinessCalenderFragmentDoc}`;

/**
 * __useDraftQuotationPageQuery__
 *
 * To run a query within a React component, call `useDraftQuotationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftQuotationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftQuotationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDraftQuotationPageQuery(baseOptions?: Apollo.QueryHookOptions<DraftQuotationPageQuery, DraftQuotationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftQuotationPageQuery, DraftQuotationPageQueryVariables>(DraftQuotationPageDocument, options);
      }
export function useDraftQuotationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftQuotationPageQuery, DraftQuotationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftQuotationPageQuery, DraftQuotationPageQueryVariables>(DraftQuotationPageDocument, options);
        }
export type DraftQuotationPageQueryHookResult = ReturnType<typeof useDraftQuotationPageQuery>;
export type DraftQuotationPageLazyQueryHookResult = ReturnType<typeof useDraftQuotationPageLazyQuery>;
export type DraftQuotationPageQueryResult = Apollo.QueryResult<DraftQuotationPageQuery, DraftQuotationPageQueryVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const AddDraftQuotationDetailDocument = gql`
    mutation AddDraftQuotationDetail($commands: [DraftQuotationDetailAddCommand!]!) {
  addDraftQuotationDetail(commands: $commands) {
    ...DraftQuotationWithDetails
  }
}
    ${DraftQuotationWithDetailsFragmentDoc}`;
export type AddDraftQuotationDetailMutationFn = Apollo.MutationFunction<AddDraftQuotationDetailMutation, AddDraftQuotationDetailMutationVariables>;

/**
 * __useAddDraftQuotationDetailMutation__
 *
 * To run a mutation, you first call `useAddDraftQuotationDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDraftQuotationDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDraftQuotationDetailMutation, { data, loading, error }] = useAddDraftQuotationDetailMutation({
 *   variables: {
 *      commands: // value for 'commands'
 *   },
 * });
 */
export function useAddDraftQuotationDetailMutation(baseOptions?: Apollo.MutationHookOptions<AddDraftQuotationDetailMutation, AddDraftQuotationDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDraftQuotationDetailMutation, AddDraftQuotationDetailMutationVariables>(AddDraftQuotationDetailDocument, options);
      }
export type AddDraftQuotationDetailMutationHookResult = ReturnType<typeof useAddDraftQuotationDetailMutation>;
export type AddDraftQuotationDetailMutationResult = Apollo.MutationResult<AddDraftQuotationDetailMutation>;
export type AddDraftQuotationDetailMutationOptions = Apollo.BaseMutationOptions<AddDraftQuotationDetailMutation, AddDraftQuotationDetailMutationVariables>;
export const UpdateDraftQuotationDocument = gql`
    mutation UpdateDraftQuotation($note: String, $orderStatus: String) {
  updateDraftQuotation(note: $note, orderStatus: $orderStatus) {
    ...DraftQuotationWithDetails
  }
}
    ${DraftQuotationWithDetailsFragmentDoc}`;
export type UpdateDraftQuotationMutationFn = Apollo.MutationFunction<UpdateDraftQuotationMutation, UpdateDraftQuotationMutationVariables>;

/**
 * __useUpdateDraftQuotationMutation__
 *
 * To run a mutation, you first call `useUpdateDraftQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftQuotationMutation, { data, loading, error }] = useUpdateDraftQuotationMutation({
 *   variables: {
 *      note: // value for 'note'
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useUpdateDraftQuotationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDraftQuotationMutation, UpdateDraftQuotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDraftQuotationMutation, UpdateDraftQuotationMutationVariables>(UpdateDraftQuotationDocument, options);
      }
export type UpdateDraftQuotationMutationHookResult = ReturnType<typeof useUpdateDraftQuotationMutation>;
export type UpdateDraftQuotationMutationResult = Apollo.MutationResult<UpdateDraftQuotationMutation>;
export type UpdateDraftQuotationMutationOptions = Apollo.BaseMutationOptions<UpdateDraftQuotationMutation, UpdateDraftQuotationMutationVariables>;
export const DeleteDraftQuotationDocument = gql`
    mutation DeleteDraftQuotation {
  deleteDraftQuotation
}
    `;
export type DeleteDraftQuotationMutationFn = Apollo.MutationFunction<DeleteDraftQuotationMutation, DeleteDraftQuotationMutationVariables>;

/**
 * __useDeleteDraftQuotationMutation__
 *
 * To run a mutation, you first call `useDeleteDraftQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftQuotationMutation, { data, loading, error }] = useDeleteDraftQuotationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteDraftQuotationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftQuotationMutation, DeleteDraftQuotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftQuotationMutation, DeleteDraftQuotationMutationVariables>(DeleteDraftQuotationDocument, options);
      }
export type DeleteDraftQuotationMutationHookResult = ReturnType<typeof useDeleteDraftQuotationMutation>;
export type DeleteDraftQuotationMutationResult = Apollo.MutationResult<DeleteDraftQuotationMutation>;
export type DeleteDraftQuotationMutationOptions = Apollo.BaseMutationOptions<DeleteDraftQuotationMutation, DeleteDraftQuotationMutationVariables>;
export const UpdateDraftQuotationDetailDocument = gql`
    mutation updateDraftQuotationDetail($draftQuotationDetailId: Long!, $wholesaleId: String, $note: String) {
  updateDraftQuotationDetail(
    draftQuotationDetailId: $draftQuotationDetailId
    wholesaleId: $wholesaleId
    note: $note
  ) {
    ...DraftQuotationWithDetails
  }
}
    ${DraftQuotationWithDetailsFragmentDoc}`;
export type UpdateDraftQuotationDetailMutationFn = Apollo.MutationFunction<UpdateDraftQuotationDetailMutation, UpdateDraftQuotationDetailMutationVariables>;

/**
 * __useUpdateDraftQuotationDetailMutation__
 *
 * To run a mutation, you first call `useUpdateDraftQuotationDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftQuotationDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftQuotationDetailMutation, { data, loading, error }] = useUpdateDraftQuotationDetailMutation({
 *   variables: {
 *      draftQuotationDetailId: // value for 'draftQuotationDetailId'
 *      wholesaleId: // value for 'wholesaleId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateDraftQuotationDetailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDraftQuotationDetailMutation, UpdateDraftQuotationDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDraftQuotationDetailMutation, UpdateDraftQuotationDetailMutationVariables>(UpdateDraftQuotationDetailDocument, options);
      }
export type UpdateDraftQuotationDetailMutationHookResult = ReturnType<typeof useUpdateDraftQuotationDetailMutation>;
export type UpdateDraftQuotationDetailMutationResult = Apollo.MutationResult<UpdateDraftQuotationDetailMutation>;
export type UpdateDraftQuotationDetailMutationOptions = Apollo.BaseMutationOptions<UpdateDraftQuotationDetailMutation, UpdateDraftQuotationDetailMutationVariables>;
export const DeleteDraftQuotationDetailDocument = gql`
    mutation deleteDraftQuotationDetail($draftQuotationDetailId: Long!) {
  deleteDraftQuotationDetail(draftQuotationDetailId: $draftQuotationDetailId) {
    ...DraftQuotationWithDetails
  }
}
    ${DraftQuotationWithDetailsFragmentDoc}`;
export type DeleteDraftQuotationDetailMutationFn = Apollo.MutationFunction<DeleteDraftQuotationDetailMutation, DeleteDraftQuotationDetailMutationVariables>;

/**
 * __useDeleteDraftQuotationDetailMutation__
 *
 * To run a mutation, you first call `useDeleteDraftQuotationDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftQuotationDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftQuotationDetailMutation, { data, loading, error }] = useDeleteDraftQuotationDetailMutation({
 *   variables: {
 *      draftQuotationDetailId: // value for 'draftQuotationDetailId'
 *   },
 * });
 */
export function useDeleteDraftQuotationDetailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftQuotationDetailMutation, DeleteDraftQuotationDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftQuotationDetailMutation, DeleteDraftQuotationDetailMutationVariables>(DeleteDraftQuotationDetailDocument, options);
      }
export type DeleteDraftQuotationDetailMutationHookResult = ReturnType<typeof useDeleteDraftQuotationDetailMutation>;
export type DeleteDraftQuotationDetailMutationResult = Apollo.MutationResult<DeleteDraftQuotationDetailMutation>;
export type DeleteDraftQuotationDetailMutationOptions = Apollo.BaseMutationOptions<DeleteDraftQuotationDetailMutation, DeleteDraftQuotationDetailMutationVariables>;
export const SubmitQuotationDocument = gql`
    mutation SubmitQuotation($note: String) {
  submitQuotation(note: $note)
}
    `;
export type SubmitQuotationMutationFn = Apollo.MutationFunction<SubmitQuotationMutation, SubmitQuotationMutationVariables>;

/**
 * __useSubmitQuotationMutation__
 *
 * To run a mutation, you first call `useSubmitQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuotationMutation, { data, loading, error }] = useSubmitQuotationMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useSubmitQuotationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuotationMutation, SubmitQuotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitQuotationMutation, SubmitQuotationMutationVariables>(SubmitQuotationDocument, options);
      }
export type SubmitQuotationMutationHookResult = ReturnType<typeof useSubmitQuotationMutation>;
export type SubmitQuotationMutationResult = Apollo.MutationResult<SubmitQuotationMutation>;
export type SubmitQuotationMutationOptions = Apollo.BaseMutationOptions<SubmitQuotationMutation, SubmitQuotationMutationVariables>;

export type CancelQuotationDetailMutationFn = Apollo.MutationFunction<CancelQuotationDetailMutationVariables, CancelQuotationDetailMutationVariables>;

/**
 * __useUpdateDraftQuotationMutation__
 *
 * To run a mutation, you first call `useUpdateDraftQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftQuotationMutation, { data, loading, error }] = useUpdateDraftQuotationMutation({
 *   variables: {
 *      note: // value for 'note'
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useCancelQuotationDetailMutation(baseOptions?: Apollo.MutationHookOptions<CancelQuotationDetailMutation, CancelQuotationDetailMutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CancelQuotationDetailMutation, CancelQuotationDetailMutationVariables>(CancelQuotationDetailDocument, options);
}
export type CancelQuotationDetailMutationHookResult = ReturnType<typeof useCancelQuotationDetailMutation>;
export type CancelQuotationDetailMutationResult = Apollo.MutationResult<CancelQuotationDetailMutation>;
export type CancelQuotationDetailMutationOptions = Apollo.BaseMutationOptions<CancelQuotationDetailMutation, CancelQuotationDetailMutationVariables>;

export const CancelQuotationDetailDocument = gql`
    mutation CancelQuotationDetail($quotationId: String!, $quotationDetailId: String!) {
  cancelQuotationDetail(quotationId: $quotationId, quotationDetailId: $quotationDetailId) {
    ...Quotation
  }
}
    ${QuotationFragmentDoc}`;