import { Button, Modal, Alert, Container, Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { formatDate, parseISODateAndformatDate } from "utils/miscellaneous";
import {
  useSubmitQuotationMutation,
  useDraftQuotationPageLazyQuery,
  DraftQuotationPageQueryResult,
} from "features/graphql";
import { Loading } from "components/Loading";
import { graphQlErrorMessage } from "utils/miscellaneous";

interface ModalProps {
  show: boolean;
  note?: string;
  handleOk: () => void;
  handleCancel: () => void;
}

export const SubmitQuotationModal = ({ show, note, handleOk, handleCancel }: ModalProps) => {
  const [pageData, setPageData] = useState<DraftQuotationPageQueryResult["data"] | null>(null);

  const [draftQuotationLazyQuery, { loading: pageLoading }] = useDraftQuotationPageLazyQuery({
    onCompleted(data) {
      setPageData(data);
    },
  });

  const [submitQuotationMutation, { loading, error }] = useSubmitQuotationMutation({
    onCompleted: () => {
      handleOk();
    },
  });

  useEffect(() => {
    if (show) {
      draftQuotationLazyQuery();
    }
  }, [show, draftQuotationLazyQuery]);

  const draftDetails = pageData?.draftQuotation?.details ?? [];

  const onAdd = () => {
    submitQuotationMutation({variables: { note }});
  };

  return (
    <Modal show={show} size={"xl"}>
      <Modal.Body className="">
        <Container fluid="xl" className="px-5 pb-5">
          <Row></Row>
          <Row className="mt-4">
            <Col>
              <div className="fs-3">内容確認</div>
            </Col>
            <Col></Col>
            <Col className="d-flex justify-content-end">
              <table>
                <tbody>
                  <tr>
                    <td>依頼日:</td>
                    <td>{formatDate(new Date())}</td>
                  </tr>
                  <tr>
                    <td>回答予定日:</td>
                    <td>{parseISODateAndformatDate(pageData?.selectBusinessCalender.businessDay)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <Col>{error && <Alert variant="danger">{graphQlErrorMessage(error)}</Alert>}</Col>
          </Row>

          <Row className="mt-3">
            <Table className="table-borderless">
              <thead className="text-success border-bottom border-success border-3">
                <tr>
                  <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                    No
                  </th>
                  <th rowSpan={2} className="align-middle" style={{ width: "3rem" }}>
                    区分
                  </th>
                  <th style={{ width: "35rem" }}>品名</th>
                  <th style={{ width: "15rem" }}>販売元名</th>
                  <th style={{ width: "10rem" }}>JANコード</th>
                  <th style={{ width: "10rem" }}>YJコード</th>
                  <th rowSpan={2}></th>
                </tr>
                <tr>
                  <th>規格</th>
                  <th>指定卸</th>
                  <th></th>
                  <th>GS1コード</th>
                </tr>
              </thead>
              {draftDetails.map((detail, i) => {
                return (
                  <tbody key={detail.draftQuotationDetailId}>
                    <tr>
                      <td rowSpan={2} className="align-middle">
                        {i + 1}
                      </td>
                      <td rowSpan={2} className="align-middle">
                        {detail.medicineType}
                      </td>
                      <td>
                        <span className="text-success">{detail.medicineName}</span>
                      </td>
                      <td className="border-bottom">{detail.salesManufacturer} </td>
                      <td className="border-bottom">{detail.janCode}</td>
                      <td className="border-bottom">{detail.yjCode}</td>
                    </tr>
                    <tr>
                      <td>{detail?.capacity}</td>
                      <td>{detail?.wholesaleName}</td>
                      <td></td>
                      <td>{detail.gs1Code}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td colSpan={3}></td>
                      <td colSpan={3} className="text-secondary text-break text-pre-wrap">
                        {detail.note}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              <tfoot className="border-top border-success border-3">
                <tr></tr>
              </tfoot>
            </Table>
          </Row>
          <Row>
            <Col className="fs-4 text-center">
              依頼内容をご確認のうえ、送信ボタンを押してください。
              <br />
              回答予定日までに<span className="text-success">info-gpo@sogo-medical.co.jp</span> より、
              <br />
              ご登録のメールアドレス宛にご案内申し上げます。
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-top-0 d-flex justify-content-center">
        <Button
          className="me-3"
          variant="secondary"
          onClick={() => {
            handleCancel();
          }}
        >
          戻る
        </Button>
        <Button variant="success" type="button" onClick={() => onAdd()}>
          見積送信
        </Button>
      </Modal.Footer>
      {(loading || pageLoading) && <Loading />}
    </Modal>
  );
};
