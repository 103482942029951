import { useEffect, useState, useMemo, ReactNode } from 'react';
import {  Form,  Pagination } from 'react-bootstrap';

interface Props {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  pageSize: number,
  onPageChange: (page: number) => void;
  onPageSize: (value: number) => void;
  children?: ReactNode;
}

export const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage,
  pageSize = 10,
  onPageChange,
  onPageSize,
  children
}: Props) => {
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(itemsPerPage);
  const [selectedOption, setSelectedOption] = useState(pageSize);
 
  const onPagesSize = (value: number) => {
    setItemPerPage(Number(value));
  };
  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemPerPage))
    } else {
      setTotalPages(0)
    };
    setSelectedOption(pageSize);
  }, [total, itemPerPage, itemsPerPage, pageSize]);

  const paginationItems: ReactNode[] = useMemo(() => {
    const pages: ReactNode[] = [];
    let displayPages = [1];
    if (1 < totalPages) {
      displayPages.push(totalPages);
    }

    for (let i = -1; i <= 1; i++) {
      const pageNumber = currentPage + i;
      if ((1 < pageNumber)
        && (pageNumber < totalPages)
        && (-1 === displayPages.indexOf(pageNumber))) {
        displayPages.push(pageNumber);
      }
    }
    displayPages.sort((a, b)=> a - b);

    displayPages.forEach((page, i) => {
      const existGap = (i !== 0) && (1 < (page - displayPages[i - 1]))
      if (existGap) {
          pages.push(<Pagination.Ellipsis key={page - 1} />);
      }
      pages.push(
        <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
          {page}
        </Pagination.Item>
      );
    });

    return pages;
  }, [totalPages, currentPage, onPageChange]);

  if (totalPages === 0) {
    return null
  };

  return (
    <div className='d-flex justify-content-between'>
      <div>
        <Pagination >
          <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
          {paginationItems}
          <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
      </div>

      {children}

      <div style={{width: '16rem'}}>
        <Form.Select
          className='ms-auto w-auto'
          value={selectedOption}  // 選択されたオプションを表示する
          onChange={(e) => {
            const value = Number(e.target.value);
            setSelectedOption(value);
            onPagesSize(value);
            onPageSize(value);

          }}
        > 
          <option value={10}>10件表示</option>
          <option value={20}>20件表示</option>
          <option value={50}>50件表示</option>
        </Form.Select>
      </div>
    </div>
  );
};
